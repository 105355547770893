import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";

import {
  Phone
} from "../../ScalableVectorGraphics";


type ComponentStyle = {
  templateColumns: object | string
  gap: object | string
}

interface Props {
  node: any
  hasImageFrame?: boolean | undefined

  style?: ComponentStyle | undefined
}


export const ImageWithText: React.FC<Props> = ( props ) => {
  return (
    <Flex
      position="relative"
      flexDirection={{
        base: 'column-reverse',
        lg: 'row'
      }}
      justifyContent={{
        lg: 'space-between'
      }}
      alignItems={{
        base: 'center',
        lg: 'flex-start'
      }}
      w="100%"
    >
      <Box
        position="relative"
        w={{
          base: '288px',
          sm: '514px',
          lg: '400px',
          xl: '514px'
        }}
        h={{
          base: '288px',
          sm: '514px',
          lg: '400px',
          xl: '514px'
        }}
        m={{
          base: '48px 0 0',
          lg: '0 48px 0 0'
        }}
      >
        { props.hasImageFrame &&
          <Box
            position="absolute"
            right={ 0 }
            bottom={ 0 }
            w={{
              base: '0',
              sm: '450px',
              lg: '350px',
              xl: '450px'
            }}
            h={{
              base: '0',
              sm: '450px',
              lg: '350px',
              xl: '450px'
            }}
            bg="background.azureishwhite"
            borderRadius="0px 0px 39px 0px"
          />
        }
        <Box
          position="absolute"
          top={ 0 }
          left={ 0 }
          w={{
            base: '100%',
            sm: '450px',
            lg: '350px',
            xl: '450px'
          }}
          h={{
            base: '100%',
            sm: '450px',
            lg: '350px',
            xl: '450px'
          }}
          borderRadius="0px 0px 39px 0px"
        >
          <Image
            as={ GatsbyImage }
            image={ props.node.image }
            w={{
              base: '100%',
              sm: '450px',
              lg: '350px',
              xl: '450px'
            }}
            h={{
              base: '100%',
              sm: '450px',
              lg: '350px',
              xl: '450px'
            }}
            borderRadius="0px 0px 39px 0px"
            imgStyle={{
              borderRadius: "0px 0px 39px 0px"
            }}
          />
        </Box>
      </Box>
      <Box
        w={{
          base: '100%',
          lg: '50%'
        }}
      >
        <VStack
          justify="flex-start"
          align="flex-start"
          spacing="24px"
          w="100%"
        >
          <Heading
            as="h2"
            color="text.primary"
            fontSize="36px"
            fontWeight={ 700 }
          >
            { props.node.heading }
          </Heading>
          { props.node.description &&
            <>
              { props.node.description.map( ( paragraph: string, _index: number ) => (
                <Text
                  key={ _index }
                  color="text.primary"
                  fontSize="16px"
                  fontWeight={ 400 }
                >
                  { paragraph }
                </Text>
              ))}
            </>
          }
          { props.node.hasCallButton &&
            <HStack
              justify="center"
              align="flex-start"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                w="40px"
                h="40px"
                bg="primary.900"
                borderRadius="full"
              >
                <Phone
                  w="16px"
                  h="16px"
                  color="white"
                />
              </Flex>
              <VStack
                align="flex-end"
                spacing="0"
                pt="4px"
              >
                <Button
                  as={ Link }
                  href="tel:+19059700888"
                  variant="link"
                  color="primary.900"
                  fontSize="19px"
                  fontWeight={ 700 }
                  _hover={{
                    opacity: '0.6',
                    textDecoration: 'none'
                  }}
                >
                  905-970-0888
                </Button>
                <Button
                  as={ Link }
                  href="#contact"
                  variant="link"
                  color="text.primary"
                  fontWeight={ 500 }
                  textDecoration="underline"
                  _hover={{
                    opacity: '0.6'
                  }}
                >
                  Request a Call
                </Button>
              </VStack>
            </HStack>
          }
        </VStack>
      </Box>
    </Flex>
  )
}
