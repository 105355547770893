import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Flex,
  Image
} from "@chakra-ui/react";


type ComponentStyle = {
  right: object
  top: object
  display: boolean
  zIndex: object
}

interface Props {
  node: any
  index: number
  selectedIndex: number
  selected: boolean

  style: ComponentStyle
}


export const TestimonialThumb: React.FC<Props> = ( props ) => {
  return (
    <Flex
      position="absolute"
      right={ props.selected ? '0' : props.style.right }
      top={ props.selected ? {
        lg: 'calc( 50% - 86px )'
      } : props.style.top }
      justifyContent="center"
      alignItems="center"
      w={{
        lg: '172px'
      }}
      h={{
        lg: '172px'
      }}
      border={ props.selected ? '1px' : '0' }
      borderColor="border.gainsboro"
      borderRadius={ props.selected ? '22px' : '16px' }
      opacity={ props.style.display === true ? '1' : '0' }
      transition="all 0.5s ease-in-out"
    >
      <Box
        w={{
          lg: '150px'
        }}
        h={{
          lg: '150px'
        }}
        borderRadius="16px"
      >
        <Image
          as={ GatsbyImage }
          image={ props.node.frontmatter.image.childImageSharp.gatsbyImageData }
          w={{
            lg: '150px'
          }}
          h={{
            lg: '150px'
          }}
          borderRadius="16px"
          imgStyle={{
            borderRadius: "16px"
          }}
        />
      </Box>
    </Flex>
  )
}
