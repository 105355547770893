import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack
} from "@chakra-ui/react";

import { EmblaOptionsType } from "embla-carousel-react";

import {
  ArrowLeft,
  ArrowRight
} from "../ScalableVectorGraphics";

import {
  Project,
  Testimonial,
  TestimonialThumb
} from "./Templates";


type ComponentStyle = {
  align?: "flex-start" | "center" | "flex-end" | undefined
  textAlign?: "left" | "center" | undefined
}

interface Props {
  nodes: any[]
  options: EmblaOptionsType
  template: string
  splitView?: boolean | undefined

  heading?: React.ReactNode | string | undefined
  description?: string | undefined

  style?: ComponentStyle | undefined
}


const getPositionX = (
  index: number,
  selectedIndex: number,
  screenSize: "lg" | "xl"
) => {
  if ( selectedIndex === 9 ) {
    if ( index === 0 ) {
      return screenSize === "lg" ? 200 : 220;
    } else if ( index === 1 ) {
      return screenSize === "lg" ? 250 : 300;
    }
  } else if ( selectedIndex === 8 ) {
    if ( index === 9 ) {
      return screenSize === "lg" ? 200 : 220;
    } else if ( index === 0 ) {
      return screenSize === "lg" ? 250 : 300;
    }
  }

  if ( index === selectedIndex + 1 ) {
    return screenSize === "lg" ? 200 : 220;
  } else if ( index === selectedIndex + 2 ) {
    return screenSize === "xl" ? 300 : 250;
  }

  return 0;
};

const getPositionY = (
  index: number,
  selectedIndex: number
) => {
  if ( selectedIndex === 9 ) {
    if ( index === 0 ) {
      return 300;
    } else if ( index === 1 ) {
      return 0;
    }
  } else if ( selectedIndex === 8 ) {
    if ( index === 9 ) {
      return 300;
    } else if ( index === 0 ) {
      return 0;
    }
  }

  if ( index === selectedIndex + 1 ) {
    return 300;
  } else if ( index === selectedIndex + 2 ) {
    return 0;
  }

  return -100;
};

const testimonialThumbVisible = (
  index: number,
  selectedIndex: number
) => {
  if ( selectedIndex === 9 ) {
    if ( index === 0 ) {
      return true;
    } else if ( index === 1 ) {
      return true;
    } else if ( index === selectedIndex ) {
      return true;
    } else {
      return false;
    }
  } else if ( selectedIndex === 8 ) {
    if ( index === 9 ) {
      return true;
    } else if ( index === 0 ) {
      return true;
    } else if ( index === selectedIndex ) {
      return true;
    } else {
      return false;
    }
  }

  return index === selectedIndex || index === selectedIndex + 1 || index === selectedIndex + 2;
};


export const Carousel: React.FC<Props> = ( props ) => {
  const [viewportRef, embla] = useEmblaCarousel( props.options );
  const [prevButtonEnabled, setPrevButtonEnabled] = useState( false );
  const [nextButtonEnabled, setNextButtonEnabled] = useState( false );
  const [selectedIndex, setSelectedIndex] = useState( 0 );

  const scrollPrev = useCallback( () => embla && embla.scrollPrev(), [embla] );
  const scrollNext = useCallback( () => embla && embla.scrollNext(), [embla] );

  const onSelect = useCallback( () => {
    if ( !embla ) return;

    setSelectedIndex( embla.selectedScrollSnap() );
    setPrevButtonEnabled( embla.canScrollPrev() );
    setNextButtonEnabled( embla.canScrollNext() );
  }, [embla, setSelectedIndex] );

  useEffect(() => {
    if ( !embla ) return;

    onSelect();

    embla.on( "select", onSelect );
  }, [embla, onSelect] );

  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'project':
        return (
          <Project
            key={ _index }
            node={ node.node }
          />
        );
      case 'testimonial':
        return (
          <Testimonial
            key={ node.node.frontmatter.image.publicURL }
            node={ node.node }
          />
        );
      default:
        return null;
    }
  });

  return (
    <VStack
      justify="flex-start"
      align={ props.style ? ( props.style.align ? props.style.align : 'flex-start' ) : 'flex-start' }
      spacing="32px"
    >
      { props.heading && !props.splitView &&
        <VStack
          justify="flex-start"
          align={ props.style ? ( props.style.align ? props.style.align : 'flex-start' ) : 'center' }
          spacing="24px"
        >
          <Heading
            as="h2"
            color="text.primary"
            fontSize="36px"
            fontWeight={ 700 }
            textAlign={ props.style ? ( props.style.textAlign ? props.style.textAlign : 'left' ) : 'left' }
          >
            { props.heading }
          </Heading>
          { props.description &&
            <Text
              color="text.primary"
              fontSize="16px"
              fontWeight={ 400 }
              textAlign={ props.style ? ( props.style.textAlign ? props.style.textAlign : 'left' ) : 'left' }
            >
              { props.description }
            </Text>
          }
        </VStack>
      }
      <Flex
        flexDirection="row"
        alignItems="center"
        w="100%"
      >
        { props.splitView && props.template === 'testimonial' &&
          <Flex
            position="relative"
            display={{
              base: 'none',
              lg: 'block'
            }}
            w="50%"
            h={{
              lg: '472px'
            }}
            mr="70px"
          >
            { props.nodes.map( ( node: any, _index: number ) => (
              <TestimonialThumb
                key={ _index }
                node={ node.node }
                index={ _index }
                selectedIndex={ selectedIndex }
                selected={ _index === selectedIndex }
                style={{
                  display: testimonialThumbVisible( _index, selectedIndex ),
                  right: {
                    'lg': getPositionX( _index, selectedIndex, "lg" ),
                    'xl': getPositionX( _index, selectedIndex, "xl" )
                  },
                  top: {
                    'lg': getPositionY( _index, selectedIndex )
                  },
                  zIndex: {
                    'lg': ( _index - selectedIndex )
                  }
                }}
              />
            ))}
          </Flex>
        }
        <VStack
          justify="flex-start"
          align={ props.style ? ( props.style.align ? props.style.align : 'flex-start' ) : 'flex-start' }
          spacing="32px"
          w={ props.splitView ? {
            base: '100%',
            lg: '50%'
          } : '100%' }
        >
          { props.splitView &&
            <VStack
              justify="flex-start"
              align={ props.style ? ( props.style.align ? props.style.align : 'flex-start' ) : 'center' }
              spacing="24px"
            >
              <Heading
                as="h2"
                color="text.primary"
                fontSize="36px"
                fontWeight={ 700 }
                textAlign={ props.style ? ( props.style.textAlign ? props.style.textAlign : 'left' ) : 'left' }
              >
                { props.heading }
              </Heading>
              { props.description &&
                <Text
                  color="text.primary"
                  fontSize="16px"
                  fontWeight={ 400 }
                  textAlign={ props.style ? ( props.style.textAlign ? props.style.textAlign : 'left' ) : 'left' }
                >
                  { props.description }
                </Text>
              }
            </VStack>
          }
          <Box className="Embla"
            position="relative"
            w="100%"
          >
            <Box className="EmblaViewport"
              ref={ viewportRef }
              overflow="hidden"
              w="100%"
              _hover={{
                cursor: 'grab'
              }}
              _active={{
                cursor: 'grabbing'
              }}
            >
              <Flex className="EmblaContainer"
                userSelect="none"
                css={{
                  WebkitTouchCallout: 'none',
                  KhtmlUserSelect: 'none',
                  WebkitTapHighlightColor: 'transparent'
                }}
              >
                { nodeList }
              </Flex>
            </Box>
          </Box>
          { props.splitView &&
            <HStack
              justify={ props.style ? ( props.style.align ? props.style.align : 'flex-start' ) : 'flex-start' }
              align="center"
              spacing="8px"
            >
              <IconButton
                aria-label="Previous slide"
                icon={ <ArrowLeft color="primary.900" /> }
                disabled={ !prevButtonEnabled }
                onClick={ scrollPrev }
                background="transparent"
                color="primary.900"
                borderWidth="1px"
                borderColor="primary.900"
                borderRadius="50%"
                _hover={{
                  opacity: '0.6'
                }}
              />
              <IconButton
                aria-label="Next slide"
                icon={ <ArrowRight color="primary.900" /> }
                disabled={ !nextButtonEnabled }
                onClick={ scrollNext }
                background="transparent"
                color="primary.900"
                borderWidth="1px"
                borderColor="primary.900"
                borderRadius="50%"
                _hover={{
                  opacity: '0.6'
                }}
              />
            </HStack>
          }
        </VStack>
      </Flex>
      { !props.splitView &&
        <HStack
          justify={ props.style ? ( props.style.align ? props.style.align : 'flex-start' ) : 'flex-start' }
          align="center"
          spacing="8px"
        >
          <IconButton
            aria-label="Previous slide"
            icon={ <ArrowLeft color="primary.900" /> }
            disabled={ !prevButtonEnabled }
            onClick={ scrollPrev }
            background="transparent"
            color="primary.900"
            borderWidth="1px"
            borderColor="primary.900"
            borderRadius="50%"
            _hover={{
              opacity: '0.6'
            }}
          />
          <IconButton
            aria-label="Next slide"
            icon={ <ArrowRight color="primary.900" /> }
            disabled={ !nextButtonEnabled }
            onClick={ scrollNext }
            background="transparent"
            color="primary.900"
            borderWidth="1px"
            borderColor="primary.900"
            borderRadius="50%"
            _hover={{
              opacity: '0.6'
            }}
          />
        </HStack>
      }
    </VStack>
  )
}
