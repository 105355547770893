import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Flex,
  Image
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const Project: React.FC<Props> = ( props ) => {
  return (
    <Flex
      position="relative"
      justifyContent="center"
      minW="100%"
      w="100%"
    >
      <Box
        position="relative"
        w={{
          base: '290px',
          sm: '600px',
          md: '700px',
          lg: '780px'
        }}
        h={{
          base: '200px',
          sm: '392px',
          md: '458px',
          lg: '510px'
        }}
      >
        <Box
          position="absolute"
          top={ 0 }
          left={ 0 }
          w={{
            base: '0',
            sm: '477px',
            md: '556px',
            lg: '620px'
          }}
          h={{
            base: '0',
            sm: '185px',
            md: '215px',
            lg: '240px'
          }}
          bg="background.azureishwhite"
          borderRadius={{
            base: '0 28px 0 0'
          }}
        />
        <Box
          position="absolute"
          right={ 0 }
          bottom={ 0 }
          w={{
            base: '100%',
            sm: '538px',
            md: '628px',
            lg: '700px'
          }}
          h="auto"
          borderRadius={{
            base: '0 28px'
          }}
        >
          <Image
            as={ GatsbyImage }
            image={ props.node.frontmatter.image.childImageSharp.gatsbyImageData }
            w={{
              base: '100%',
              sm: '538px',
              md: '628px',
              lg: '700px'
            }}
            h="auto"
            borderRadius={{
              base: '0 28px'
            }}
            imgStyle={{
              borderRadius: "0 28px"
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
}
