import * as React from "react";
import {
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";

import {
  CallToAction,
  Contact,
  ImageWithText
} from "./Templates";


interface Props {
  nodes: any[]
  layout: string
  childNodes: any[]

  heading?: React.ReactNode | string | undefined
  justify?: "center" | "flex-start" | undefined
  align?: "center" | "flex-start" | undefined
  width?: object | string | undefined
  description?: string | undefined
}


export const Flexbox: React.FC<Props> = ( props ) => {
  const nodeList: React.ReactNode[] = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.layout ) {
      case 'call-to-action':
        return (
          <CallToAction
            key={ _index }
            node={ node.node }
            style={ node.node.style }
          />
        );
      case 'contact':
        return (
          <Contact
            key={ _index }
            node={ node.node }
          />
        );
      case 'image-with-text':
        return (
          <ImageWithText
            key={ _index }
            node={ node.node }
            hasImageFrame={ node.node.hasImageFrame }
            style={ node.node.style }
          />
        );
      default:
        return null;
    }
  });

  return (
    <VStack
      align="flex-start"
      spacing="80px"
    >
      { props.heading &&
        <VStack
          justify={ props.justify }
          align={ props.align }
          spacing="24px"
          w={ props.width ? props.width : '100%' }
        >
          <Heading
            as="h2"
            color="text.primary"
            fontSize="48px"
            fontWeight={ 700 }
          >
            { props.heading }
          </Heading>
          { props.description &&
            <Text
              color="text.primary"
              fontSize="16px"
              fontWeight={ 400 }
            >
              { props.description }
            </Text>
          }
        </VStack>
      }
      <VStack
        spacing="72px"
        w="100%"
      >
        { nodeList }
      </VStack>
    </VStack>
  )
}
