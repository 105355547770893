import * as React from "react";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import {
  Box,
  Image as ChakraImage
} from "@chakra-ui/react";


type ComponentStyle = {
  borderRadius: string
}

interface Props {
  node: any

  style?: ComponentStyle | undefined
}


export const Image: React.FC<Props> = ( props ) => {
  const images = withArtDirection( props.node.location_image.desktop.childImageSharp.gatsbyImageData, [
    {
      media: '( max-width: 1024px )',
      image: props.node.location_image.mobile.childImageSharp.gatsbyImageData
    }
  ]);

  return (
    <Box
      w="100%"
      minH={{
        base: '300px'
      }}
      borderRadius={ props.style ? props.style.borderRadius : '0' }
      overflow="hidden"
    >
      <ChakraImage
        as={ GatsbyImage }
        image={ images }
        w="100%"
        minH={{
          base: '300px'
        }}
        h="auto"
        borderRadius={ props.style ? props.style.borderRadius : '0' }
        imgStyle={{
          borderRadius: props.style ? props.style.borderRadius : '0'
        }}
      />
    </Box>
  )
}
