import * as React from "react";
import {
  Grid as ChakraGrid,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";

import {
  MetalService,
  Service
} from "./Templates";


interface Props {
  nodes: any[]
  template: string
  heading?: React.ReactNode | string | undefined
  description?: string | undefined

  // Grid options
  templateColumns: object
  gap: object | string
}


export const Grid: React.FC<Props> = ( props ) => {
  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'metal-service':
        return (
          <MetalService
            key={ _index }
            node={ node.node }
          />
        )
      case 'service':
        return (
          <Service
            key={ _index }
            node={ node.node }
          />
        );
      default:
        return null;
    }
  });

  return (
    <VStack
      spacing="32px"
    >
      { props.heading &&
        <VStack
          spacing="24px"
        >
          <Heading
            as="h2"
            color="text.primary"
            fontSize="36px"
            fontWeight={ 700 }
          >
            { props.heading }
          </Heading>
          <Text
            color="text.primary"
            fontSize="16px"
            fontWeight={ 400 }
          >
            { props.description }
          </Text>
        </VStack>
      }
      <ChakraGrid
        templateColumns={ props.templateColumns }
        gap={ props.gap }
        w="100%"
      >
        { nodeList }
      </ChakraGrid>
    </VStack>
  )
}
