import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";


export const ContactForm: React.FC = () => {
  const twilioMailFunctionURL = 'https://malachite-falcon-6807.twil.io/contact';

  const initialValues = {
    njhsdfjk: '', // Name
    phjhsdfh: '', // Phone
    ejihsdfg: '', // Email
    ajbsdfjk: '', // Address
    sqftkjnk: 0, // Approximate Sq Footage
    prtplmkl: '', // Project Type
    mdknfken: '', // Message
    name: '', // Honeypot Name
    email: '', // Honeypot Email
    tcikjsdf: false // Terms
  };

  const validationSchema = Yup.object({
    njhsdfjk: Yup.string()
      .required('Required'),
    phjhsdfh: Yup.string()
      .required('Required'),
    ejihsdfg: Yup.string()
      .required('Required')
      .email('Invalid email address.'),
    ajbsdfjk: Yup.string()
      .required('Required'),
    sqftkjnk: Yup.number()
      .positive('Approximate square footage cannot be negative.')
      .integer('Approximate sqaure footage must be an integer.')
      .required('Required'),
    prtplmkl: Yup.string()
      .required('Required'),
    mdknfken: Yup.string()
      .required('Required'),
    name: Yup.string(),
    email: Yup.string(),
    tcikjsdf: Yup.boolean()
      .required('Required')
      .oneOf([true], 'You must accept the Terms of Use and Privacy Policy.')
  });

  const onSubmit = async (e: any, { resetForm, setStatus }: any) => {
    const {
      njhsdfjk,
      phjhsdfh,
      ejihsdfg,
      ajbsdfjk,
      sqftkjnk,
      prtplmkl,
      mdknfken,
      name,
      email,
      tcikjsdf
    } = e;

    const subject = '[DESTINY ROOFING Contact Form]';

    const response = await fetch(twilioMailFunctionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams({
        njhsdfjk,
        phjhsdfh,
        ejihsdfg,
        ajbsdfjk,
        sqftkjnk,
        prtplmkl,
        subject,
        mdknfken,
        name,
        email,
        tcikjsdf
      }),
    });

    resetForm();

    if (response.status === 200) {
      setStatus({
        success: true
      });

      window.location.replace('/confirmation');
    } else {
      setStatus({
        success: false
      });
    }
  };

  return (
    <VStack
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing="24px"
      w={{
        base: '100%',
        lg: '460px'
      }}
    >
      <Heading
        as="h3"
        color="text.primary"
        fontSize="20px"
        fontWeight={ 700 }
      >
        Fill out the form and we will be in touch shortly.
      </Heading>
      <Formik
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onSubmit={ onSubmit }
      >
        { ( formik ) => (
          <Form
            id="contact-form"
            autoComplete="off"
            style={{
              width: '100%'
            }}
          >
            <VStack
              align="flex-start"
              spacing="16px"
            >
              <Field
                name="njhsdfjk"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.njhsdfjk && form.touched.njhsdfjk }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="njhsdfjk"
                        margin="0"
                        color="text.primary"
                      >
                        Full name
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.njhsdfjk }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="njhsdfjk"
                      placeholder="Enter your full name"
                      variant="filled"
                      focusBorderColor="primary.900"
                      autoComplete="off"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="phjhsdfh"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.phjhsdfh && form.touched.phjhsdfh }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="phjhsdfh"
                        margin="0"
                        color="text.primary"
                      >
                        Phone Number
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.phjhsdfh }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="phjhsdfh"
                      placeholder="Enter your phone number"
                      variant="filled"
                      focusBorderColor="primary.900"
                      autoComplete="off"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="ejihsdfg"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.ejihsdfg && form.touched.ejihsdfg }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="ejihsdfg"
                        margin="0"
                        color="text.primary"
                      >
                        Email
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.ejihsdfg }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="ejihsdfg"
                      placeholder="Enter your email address"
                      variant="filled"
                      focusBorderColor="primary.900"
                      autoComplete="off"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="ajbsdfjk"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.ajbsdfjk && form.touched.ajbsdfjk }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="ajbsdfjk"
                        margin="0"
                        color="text.primary"
                      >
                        Address
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.ajbsdfjk }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="ajbsdfjk"
                      placeholder="Enter your physical address"
                      variant="filled"
                      focusBorderColor="primary.900"
                      autoComplete="off"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="sqftkjnk"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.sqftkjnk && form.touched.sqftkjnk }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="sqftkjnk"
                        margin="0"
                        color="text.primary"
                      >
                        Approx. Sq. Ft.
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.sqftkjnk }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="sqftkjnk"
                      placeholder="Enter your approximate square footage"
                      variant="filled"
                      focusBorderColor="primary.900"
                      autoComplete="off"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="prtplmkl"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.prtplmkl && form.touched.prtplmkl }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="prtplmkl"
                        margin="0"
                        color="text.primary"
                      >
                        Project Type
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.prtplmkl }
                      </FormErrorMessage>
                    </Flex>
                    <Select
                      id="prtplmkl"
                      placeholder="Enter your project type"
                      variant="filled"
                      focusBorderColor="primary.900"
                      { ...field }
                    >
                      <option
                        id="prtplmkl"
                        key="Residential"
                        value="Residential"
                      >
                        Residential
                      </option>
                      <option
                        id="prtplmkl"
                        key="Commercial"
                        value="Commercial"
                      >
                        Commercial
                      </option>
                      <option
                        id="prtplmkl"
                        key="Industrial"
                        value="Industrial"
                      >
                        Industrial
                      </option>
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field
                name="mdknfken"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.mdknfken && form.touched.mdknfken }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="mdknfken"
                        margin="0"
                        color="accent.900"
                      >
                        Message
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.mdknfken }
                      </FormErrorMessage>
                    </Flex>
                    <Textarea
                      id="mdknfken"
                      placeholder="Enter your message"
                      variant="filled"
                      focusBorderColor="primary.900"
                      autoComplete="off"
                      resize="none"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="name"
              >
                {({ field, form }: any) => (
                  <FormControl
                    position="absolute"
                    top={ 0 }
                    left={ 0 }
                    w={ 0 }
                    h={ 0 }
                    opacity="0"
                    zIndex={ -1000 }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="njhsdfjk"
                        margin="0"
                        color="accent.900"
                      >
                        Full name
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.njhsdfjk }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="njhsdfjk"
                      placeholder="Enter your name"
                      variant="filled"
                      focusBorderColor="primary.900"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="email"
              >
                {({ field, form }: any) => (
                  <FormControl
                    position="absolute"
                    top={ 0 }
                    left={ 0 }
                    w={ 0 }
                    h={ 0 }
                    opacity="0"
                    zIndex={ -1000 }
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      margin="0 0 8px"
                    >
                      <FormLabel
                        htmlFor="njhsdfjk"
                        margin="0"
                        color="accent.900"
                      >
                        Full name
                      </FormLabel>
                      <FormErrorMessage
                        margin="0"
                      >
                        { form.errors.njhsdfjk }
                      </FormErrorMessage>
                    </Flex>
                    <Input
                      id="njhsdfjk"
                      placeholder="Enter your name"
                      variant="filled"
                      focusBorderColor="primary.900"
                      { ...field }
                    />
                  </FormControl>
                )}
              </Field>
              <Field
                name="tcikjsdf"
              >
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={ form.errors.tcikjsdf && form.touched.tcikjsdf }
                  >
                    <Checkbox
                      id="tcikjsdf"
                      htmlFor="tcikjsdf"
                      size="lg"
                      colorScheme="primary"
                      { ...field }
                    >
                      <Text
                        fontSize="14px"
                        fontWeight={ 400 }
                      >
                        I accept the <Link as={ GatsbyLink } to="/legal/terms-of-service" color="primary.900">Terms of Service</Link> and <Link as={ GatsbyLink } to="/legal/privacy-policy" color="primary.900">Privacy Policy</Link>.
                      </Text>
                    </Checkbox>
                    <FormErrorMessage>
                      { form.errors.tcikjsdf }
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box>
                <Button
                  id="contact-submit"
                  type="submit"
                  colorScheme="primary"
                  size="lg"
                  w="100%"
                  border="1px"
                  borderColor="primary.900"
                  borderRadius="24px"
                  _hover={{
                    color: 'primary.900',
                    background: 'transparent',
                  }}
                >
                  Send Message
                </Button>
              </Box>
            </VStack>
            { formik.status &&
              <>
                { !formik.status.success &&
                  <Text
                    color="#991B1B"
                  >
                    Something went wrong. Please contact us directly at&nbsp;
                    <Link
                      href="mailto:info@destinyroofing.ca"
                      textDecoration="underline"
                      transition="all 0.2s ease-in-out"
                      _hover={{
                        opacity: '0.6'
                      }}
                    >
                      info@destinyroofing.ca
                    </Link>
                    .
                  </Text>
                }
              </>
            }
          </Form>
        )}
      </Formik>
    </VStack>
  )
}
