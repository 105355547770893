import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  VStack
} from "@chakra-ui/react";

import {
  Checkmark
} from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Service: React.FC<Props> = ( props ) => {
  const bullets = props.node.frontmatter.bullets.map( ( bullet: string, _index: number ) => {
    return (
      <ListItem
        color="primary.900"
      >
        <ListIcon as={ Checkmark } color="primary.900" />
        { bullet }
      </ListItem>
    )
  });

  return (
    <Stack
      direction={{
        base: 'column',
        sm: 'row',
        lg: 'column'
      }}
      gap="24px"
    >
      <Box
        w={{
          sm: '50%',
          lg: '100%'
        }}
        borderRadius="16px"
      >
        <Image
          as={ GatsbyImage }
          image={ props.node.frontmatter.image.childImageSharp.gatsbyImageData }
          w="100%"
          h="auto"
          borderRadius="16px"
          imgStyle={{
            borderRadius: "16px"
          }}
        />
      </Box>
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing="24px"
      >
        <Heading
          as="h3"
          color="primary.900"
          fontSize="24px"
          fontWeight={ 700 }
        >
          { props.node.frontmatter.heading }
        </Heading>
        <List
          stylePosition="inside"
        >
          { bullets }
        </List>
      </VStack>
    </Stack>
  )
}
