import * as React from "react";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";

import { ContactForm } from "../../Form";
import {
  Clock,
  Mail,
  Phone,
  Pin
} from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Contact: React.FC<Props> = ( props ) => {
  return (
    <Grid
      templateColumns={ props.node.style.templateColumns }
      gap={ props.node.style.gap }
      w="100%"
    >
      <GridItem>
        <ContactForm />
      </GridItem>
      <GridItem>
        <VStack
          justify="flex-start"
          align="flex-start"
          spacing="24px"
        >
          <Heading
            as="h3"
            color="text.primary"
            fontSize="20px"
            fontWeight={ 700 }
          >
            Contact Information.
          </Heading>
          <VStack
            justify="flex-start"
            align="flex-start"
            spacing="30px"
          >
            <HStack
              justify="flex-start"
              align="flex-start"
              spacing="24px"
            >
              <Pin
                w="16px"
                h="auto"
                color="primary.900"
              />
              <VStack
                justify="flex-start"
                align="flex-start"
                spacing="12px"
              >
                <Heading
                  as="h4"
                  color="primary.900"
                  fontSize="16px"
                  fontWeight={ 700 }
                >
                  Address
                </Heading>
                <Box>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    19 Kenview Blvd, Unit 34,
                  </Text>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    Brampton, ON L6T 5G6
                  </Text>
                </Box>
              </VStack>
            </HStack>
            <HStack
              justify="flex-start"
              align="flex-start"
              spacing="24px"
            >
              <Phone
                w="16px"
                h="auto"
                color="primary.900"
              />
              <VStack
                justify="flex-start"
                align="flex-start"
                spacing="12px"
              >
                <Heading
                  as="h4"
                  color="primary.900"
                  fontSize="16px"
                  fontWeight={ 700 }
                  lineHeight="1"
                >
                  Phone
                </Heading>
                <Box>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    Tel:&nbsp;
                    <Button
                      as={ Link }
                      href="tel:+19059700888"
                      variant="link"
                      color="primary.900"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      905-970-0888
                    </Button>
                  </Text>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    Mob:&nbsp;
                    <Button
                      as={ Link }
                      href="tel:+14168753551"
                      variant="link"
                      color="primary.900"
                      fontSize="16px"
                      fontWeight={ 400 }
                    >
                      416-875-3551
                    </Button>
                  </Text>
                </Box>
              </VStack>
            </HStack>
            <HStack
              justify="flex-start"
              align="flex-start"
              spacing="24px"
            >
              <Mail
                w="16px"
                h="auto"
                color="primary.900"
              />
              <VStack
                justify="flex-start"
                align="flex-start"
                spacing="12px"
              >
                <Heading
                  as="h4"
                  color="primary.900"
                  fontSize="16px"
                  fontWeight={ 700 }
                  lineHeight="0.75"
                >
                  Email
                </Heading>
                <Box>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    <Link
                      href="mailto:info@destinyroofing.ca"
                    >
                      info@destinyroofing.ca
                    </Link>
                  </Text>
                </Box>
              </VStack>
            </HStack>
            <HStack
              justify="flex-start"
              align="flex-start"
              spacing="24px"
            >
              <Clock
                w="16px"
                h="auto"
                color="primary.900"
              />
              <VStack
                justify="flex-start"
                align="flex-start"
                spacing="12px"
              >
                <Heading
                  as="h4"
                  color="primary.900"
                  fontSize="16px"
                  fontWeight={ 700 }
                  lineHeight="1"
                >
                  Hours
                </Heading>
                <Box>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    Monday - Saturday: 9am - 5pm
                  </Text>
                  <Text
                    color="text.primary"
                    fontSize="16px"
                    fontWeight={ 400 }
                  >
                    Sunday: By Appointment Only
                  </Text>
                </Box>
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </GridItem>
    </Grid>
  )
}
