import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  VStack
} from "@chakra-ui/react";

import {
  Quotes
} from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Testimonial: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      minW="100%"
      w="100%"
      m="0 150px"
    >
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing="24px"
        minW="100%"
        w="100%"
      >
        <Flex
          display={{
            base: 'flex',
            lg: 'none'
          }}
          justifyContent="center"
          alignItems="center"
          w="172px"
          h="172px"
          border="1px"
          borderColor="border.gainsboro"
          borderRadius="22px"
        >
          <Image
            as={ GatsbyImage }
            image={ props.node.frontmatter.image.childImageSharp.gatsbyImageData }
            w="150px"
            h="150px"
            borderRadius="16px"
            imgStyle={{
              borderRadius: "16px"
            }}
          />
        </Flex>
        <VStack
          justify="flex-start"
          align="flex-start"
          spacing="8px"
        >
          <Quotes
            w="20px"
            h="auto"
            color="primary.900"
          />
          <Heading
            as="h3"
            color="primary.900"
            fontSize="24px"
            fontWeight={ 700 }
          >
            { props.node.frontmatter.author }
          </Heading>
          <Text
            color="text.primary"
            fontSize="16px"
            fontWeight={ 400 }
          >
            Verified { props.node.frontmatter.source } Review
          </Text>
        </VStack>
        <Text
          w="100%"
          color="text.primary"
          fontSize="16px"
          fontWeight={ 400 }
        >
          { props.node.frontmatter.testimonial }
        </Text>
      </VStack>
    </Box>
  )
}
